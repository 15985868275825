import cn from 'classnames';
import { useRouter } from 'next/router';
import type { FC, ReactNodeArray } from 'react';
import { memo } from 'react';

import { Badge, Typography } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { AffSub4, IFilters } from '@src/@types/microcredits';
import { AdvertisingButton } from '@src/components/AdvertisingButton';
import { FreeButton } from '@src/components/FreeButton';
import { useMediaQuery } from '@src/components/item/hooks';
import Link from '@src/components/Link';
import { Logo } from '@src/components/Logotype';
import { Pixel } from '@src/components/Pixel';
import { GOOD_OFFER_IDS } from '@src/components/ProductList/components/Card/types';
import { ApprovalProbability } from '@src/components/ProductList/components/CardV2/components/ApprovalProbability';
import { usePageContext } from '@src/config';
import { Experiments } from '@src/constants/abTest';
import { ListKeys } from '@src/constants/microcredits';
import { getAmountString, humanizeTermRange } from '@src/helpers/common';
import { getCurrency } from '@src/helpers/currency';
import { useTestVariant } from '@src/hooks/useTestVariants';
import { useIsReviewer } from '@src/reducers/metadata';
import { isLandingPage, isSpecialPageWithoutQuery } from '@src/utils/routing';

import { ContentItem } from './ContentItem';
import styles from './styles.module.scss';
import { useCardClick } from './useCardClick';

const TURBOZAIM_CARD_ID = '5e9c22189ad83a001b4ae935';

interface ICardDesktopProps {
    className?: string;
    offer: IClientMicrocreditListItem;
    position: number;
    query?: Pick<IFilters, 'term' | 'amount'>;
}

const MIN_WIDTH_PX = 0;
const MAX_WIDTH_PX = 460;

const Card: FC<ICardDesktopProps> = memo(
    // eslint-disable-next-line complexity,max-statements
    ({ className, offer, position }) => {
        const { name, organization, advertising, approvalProbability, _id } = offer;
        const { isPartner, labelText } = advertising || {};
        const { listKey, isMainPage } = usePageContext();
        const [label] = labelText?.split(';') || [];
        const { asPath } = useRouter();
        const isSpecial = isSpecialPageWithoutQuery(asPath);
        const isMobile = useIsMobile();
        const isReviewer = useIsReviewer();

        const isLanding = isLandingPage(asPath);

        // todo: this block of code for turbozaim pixel AB testing. You should delete this after testing end.
        const { isBVariant } = useTestVariant(Experiments.TURBOZAIM);
        const shouldDontGetAffIdForTurbozaimPixelLink =
            offer._id === TURBOZAIM_CARD_ID && (isSpecial || isMainPage) && isBVariant;

        const littleMode = useMediaQuery(MIN_WIDTH_PX, MAX_WIDTH_PX);

        const badges: ReactNodeArray = [];

        if (isPartner) {
            const text = 'Mы рекомендуем';
            badges.push(<Badge key={text} color="orange" variant="primary" text={text} className={styles.badge} />);
        } else if (label) {
            badges.push(<Badge key={label} color="blue" variant="primary" text={label} className={styles.badge} />);
        }

        const currency = getCurrency(asPath);
        const amount = getAmountString(offer.details.amount.to || offer.details.amount.from, currency);
        const term = humanizeTermRange(offer.details.term, { useAlternativeFormat: true, termWithUnit: false });

        const { sourceLink, pixelDisplayLink, logoLink, cardLink, productLink, freeButtonLink } = useCardClick({
            offer,
            position,
            shouldDontGetAffIdForTurbozaimPixelLink,
        });

        const affSub4: AffSub4 = {
            organizationName: organization.name,
            productName: name,
            organizationId: organization._id,
            productId: _id,
        };

        const shouldUseSpecialItem = isSpecial || isMainPage;

        const isTemporaryTerm = isReviewer && listKey === ListKeys.WL_PAGE;

        const getTemporaryTerm = () => {
            switch (organization._id) {
                case GOOD_OFFER_IDS.WEBBANKIR:
                    return 'от 61 до 90';

                case GOOD_OFFER_IDS.ZAJMIGO:
                    return 'от 61 до 90';

                case GOOD_OFFER_IDS.EKAPUSTA:
                    return 'от 61 до 180';

                case GOOD_OFFER_IDS.KVIKU:
                    return 'от 61 до 365';

                default:
                    return term;
            }
        };

        const isAdvertisingInfoVisible = offer.advertising && !isSpecial;

        return (
            <div className={cn(styles.cardWrapper, className)}>
                <div className={cn(styles.card, styles.link, className)} data-qa="Card">
                    <Link className={styles.link_content} link={cardLink} analytics={offer}>
                        <div className={cn(styles.header)}>
                            <div className={styles.text}>
                                <Typography.Heading className={styles.text} level={6}>
                                    {organization.name}
                                </Typography.Heading>
                            </div>
                        </div>
                        {!!badges.length && !isSpecial && <div>{badges}</div>}
                        <div className={styles.space} />
                        <div className={styles.card_content}>
                            {pixelDisplayLink && (
                                <Pixel
                                    source={`${sourceLink}|position_${position}`}
                                    link={pixelDisplayLink}
                                    affSub4={affSub4}
                                    shouldGetAffId={!shouldDontGetAffIdForTurbozaimPixelLink}
                                />
                            )}
                            <div className={styles.content_row}>
                                <ContentItem className={styles.content_item} title="Сумма до" data={amount} />

                                {!isLanding && (
                                    <ContentItem
                                        className={cn(
                                            styles.content_item,
                                            shouldUseSpecialItem ? styles.content_item_special : undefined,
                                        )}
                                        classNameTitle={styles.content_item_title_special}
                                        classNameData={styles.content_item_special}
                                        title="Срок (дни)"
                                        data={isTemporaryTerm ? getTemporaryTerm() : term}
                                        wrapDataByString={littleMode ? ' до ' : undefined}
                                    />
                                )}

                                <ContentItem
                                    data={
                                        <ApprovalProbability
                                            isNewDesignCard={false}
                                            category={approvalProbability?.category}
                                        />
                                    }
                                    title="Одобрение"
                                />
                            </div>
                        </div>
                    </Link>
                    <Logo
                        className={styles.header_logo}
                        link={logoLink}
                        analytics={offer}
                        logo={organization.logotypes?.android || organization.logotypes?.square}
                        isPartner={offer.isBankPartner || offer.advertising?.isPartner}
                        size={isMobile ? 36 : 44}
                    />
                    <div className={styles.actions}>
                        {advertising ? (
                            <AdvertisingButton
                                className={styles.button}
                                source={`${sourceLink}|position${position}`}
                                advSub={advertising.advSub}
                                analytics={offer}
                                productLinkHref={productLink?.href}
                                affSub4={affSub4}
                                shouldGetAffId={!shouldDontGetAffIdForTurbozaimPixelLink}
                            />
                        ) : (
                            <FreeButton className={styles.button} analytics={offer} link={freeButtonLink} />
                        )}
                    </div>
                    {isAdvertisingInfoVisible && (
                        <Typography.Text className={styles.disclaimer}>
                            Реклама {offer.organization.fullName || offer.organization.name}
                        </Typography.Text>
                    )}
                </div>
            </div>
        );
    },
);

export default Card;
